<template>
  <navbar />
  <page-title title="Create Product" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <form @submit.prevent="submit">
      <div class="shadow overflow-hidden sm:rounded-md">
        <div class="px-4 py-5 bg-white sm:p-6">
          <div class="grid grid-cols-5 gap-6">
            <div class="col-span-6 sm:col-span-3">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Name</label
              >
              <input
                v-model="form.name"
                type="text"
                name="name"
                id="first-name"
                autocomplete="given-name"
                class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <span class="text-red-500 text-sm block mt-1" v-if="errors.name">
                {{ errors.name[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="stock" class="block text-sm font-medium text-gray-700"
                >Stock</label
              >
              <input
                v-model="form.stock"
                type="text"
                name="stock"
                id="stock"
                class="appearance-none rounded-none relative block w-full px-3 py-2 mt-1 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
              <span class="text-red-500 text-sm block mt-1" v-if="errors.stock">
                {{ errors.stock[0] }}
              </span>
            </div>

            <div class="col-span-6 sm:col-span-3" v-if="categories.data">
              <label
                for="category_id"
                class="block text-sm font-medium text-gray-700"
                >Category</label
              >
              <select
                id="category_id"
                name="category_id"
                v-model="form.category_id"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option
                  v-for="option in categories.data"
                  :value="option.id"
                  :key="option.id"
                >
                  {{ option.category_name }}
                </option>
              </select>
              <span
                class="text-red-500 text-sm block mt-1"
                v-if="errors.category_id"
              >
                {{ errors.category_id[0] }}
              </span>
              <span
                class="text-red-500 text-sm block mt-1"
                v-if="errors.message"
              >
                {{ errors.message[0] }}
              </span>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-left sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <div v-if="loading" class="btn-spinner mt-1 mr-2" />
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import PageTitle from "@/components/PageTitle.vue";
import { mapState } from "vuex";

export default {
  name: "CreateProduct",
  components: {
    Navbar,
    PageTitle,
  },
  computed: {
    ...mapState("products", {
      form: (state) => state.form,
      errors: (state) => state.errors,
      loading: (state) => state.loading,
    }),
    ...mapState("categories", {
      categories: (state) => state.categories,
    }),
  },
  mounted() {
    this.resetForm();
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.$store.dispatch("categories/getCategories");
    },
    resetForm() {
      this.$store.dispatch("products/resetProductForm");
    },
    submit() {
      this.$store.dispatch("products/storeProduct", this.form);
    },
  },
};
</script>
